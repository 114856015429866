import { SvgIcon } from '@mui/material';

export function CenterIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M11.25 22.5v-4.387L9.958 19.39l-1.054-1.053L12 15.24l3.096 3.096-1.054 1.053-1.292-1.277V22.5h-1.5Zm-5.587-7.404L4.61 14.042l1.276-1.292H1.5v-1.5h4.386L4.61 9.958l1.053-1.054L8.76 12l-3.096 3.096Zm12.674 0L15.24 12l3.096-3.096 1.053 1.054-1.277 1.292H22.5v1.5h-4.387l1.277 1.292-1.053 1.054ZM12 13.24c-.346 0-.64-.12-.88-.36s-.36-.534-.36-.88.12-.64.36-.88.534-.36.88-.36.64.12.88.36.36.534.36.88-.12.64-.36.88-.534.36-.88.36Zm0-4.48L8.904 5.663 9.958 4.61l1.292 1.276V1.5h1.5v4.386l1.292-1.276 1.054 1.053L12 8.76Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
